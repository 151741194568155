import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-app-updates",
  templateUrl: "./app-updates.component.html",
  styleUrls: ["./app-updates.component.css"],
})
export class AppUpdatesComponent implements OnInit {
  version: string;
  release_date: string;
  downloadLink: string;

  constructor() {}

  ngOnInit() {
    const roleId = JSON.parse(localStorage.getItem("currentUser")).user.role_id;
    switch (roleId) {
      //Maintenance App(Tom and PMs)
      case 2:
      case 3:
        this.downloadLink =
          "https://process-smart-attachments.s3.us-west-1.amazonaws.com/android_apk/1/Nissho+Maintenance+V2.apk";
        this.version = "v.4.0";
        this.release_date = "23/09/2022";
        break;
      //Irrigation App
      case 4:
      case 6:
        this.downloadLink =
          "https://process-smart-attachments.s3.us-west-1.amazonaws.com/android_apk/1/Nissho+Irrigation+V2.apk";
        this.version = "v.4.0";
        this.release_date = "23/09/2022";
        break;
      //Janitorial App
      case 13:
        this.downloadLink =
          "https://process-smart-attachments.s3.us-west-1.amazonaws.com/android_apk/1/Nissho+Janitorial+V2.apk";
        this.version = "v.3.0";
        this.release_date = "23/09/2022";
        break;
      default:
        this.downloadLink = "";
    }
  }
}
