import { Component, OnInit } from "@angular/core";
import { NewApiService } from "src/app/_services/newApi";
import { Form, User } from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";

@Component({
  selector: "app-retrieve-work-order",
  templateUrl: "./retrieve-work-order.component.html",
  styleUrls: ["./retrieve-work-order.component.css"],
})
export class RetrieveWorkOrderComponent implements OnInit {
  itemsList: any;
  partialItemsList: any;
  focusedId: any;
  current_work_order: String = "";
  constructor(private nas: NewApiService, private ssv: SharedService) {}

  ngOnInit() {
    this.getDeletedWorkOrder();
  }
  getDeletedWorkOrder() {
    this.nas.getDeletedWorkOrder().subscribe((data) => {
      this.itemsList = data;
      this.partialItemsList = data;
    });
  }
  onClickForm(id) {
    this.focusedId = id;
    this.current_work_order = this.itemsList.filter(
      (x) => x.header_id == id
    )[0].job_num;
  }
  retrieveWorkOrder() {
    this.nas.retrieveWorkOrder(this.focusedId).subscribe((data) => {
      document.getElementById("btn_retrieve_workorder_close").click();
      this.ssv.showSuccess("Work order retrieved successfully");
      this.getDeletedWorkOrder();
    });
  }

  searchItem(input: string) {
    if (this.partialItemsList && this.itemsList) {
      this.partialItemsList = this.itemsList.filter(
        (x) =>
          (x.job_num &&
            x.job_num.toLowerCase().includes(input.toLowerCase())) ||
          (x.project_name &&
            x.project_name.toLowerCase().includes(input.toLowerCase()))
      );
    }
  }
}
