import { Component, OnInit } from "@angular/core";
import { Form, User } from "process-smart-ng6-lib";
import {
  FormManagementService,
  DocumentConversionService,
  AuthenticationService,
} from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from "file-saver";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { NewApiService } from "src/app/_services/newApi";

@Component({
  selector: "app-view-work-order",
  templateUrl: "./view-work-order.component.html",
  styleUrls: ["./view-work-order.component.css"],
})
export class ViewWorkOrderComponent implements OnInit {
  itemsList: Array<Form>;
  partialItemsList: Array<Form>;
  usersList: Array<User>;
  irfHeadersMap: any;
  userPermission: Array<string>; //Contains a list of process numbers this role is responsible for
  typeId = "45";
  processNum: string;
  formProcessState: string;
  uid: string;
  roleId: string;
  clientId: string;
  stockTransferList: Array<Form>;
  focusedId: string;
  reject_reason: string;
  sortAsc = true;
  downloadingAll = false;
  selectedworkorderList: Array<String> = [];

  constructor(
    private fms: FormManagementService,
    private adms: AuthenticationService,
    private dcs: DocumentConversionService,
    private cds: CustomDownloadServiceService,
    private router: Router,
    private ssv: SharedService,
    private route: ActivatedRoute,
    private nas: NewApiService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.processNum = params["processNum"];
      if (this.processNum == "x") {
        this.router.navigate(["/view-work-order"]);
        return;
      }

      this.uid = JSON.parse(localStorage.getItem("currentUser")).user.uid;
      this.roleId = JSON.parse(
        localStorage.getItem("currentUser")
      ).user.role_id;
      this.clientId = JSON.parse(
        localStorage.getItem("currentUser")
      ).user.client_id;

      this.populateNames();
      this.populatePermissions();
      this.populateItems();
      this.populateSTForms();
      switch (this.processNum) {
        case "1":
          this.formProcessState = "Draft";
          break;
        case "2":
          this.formProcessState = "Waiting Management Approval";
          break;
        case "3":
          this.formProcessState = "Ready for Billing";
          break;
        case "4":
          this.formProcessState = "Pending Archival";
          break;
        default:
          this.formProcessState = "All";
          break;
      }

      this.getIRFHeadersMap();
    });
  }

  populateItems() {
    // this.fms.getForms(this.typeId, 10000, 'DESC', 0)
    //   .subscribe(itemsList => {
    //       if(this.processNum){
    //           itemsList = itemsList.filter(x => x.process_number == this.processNum);
    //       }

    //       //Exclude deleted forms
    //       itemsList = itemsList.filter(x => x.is_deleted == '0');

    //       if(itemsList){
    //           this.itemsList = itemsList.sort((a, b) => {
    //               return (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
    //           });
    //       }
    //       this.partialItemsList = itemsList;
    //   });

    this.nas.getWorkOrders(this.typeId).subscribe((itemsList) => {
      if (this.processNum) {
        itemsList = itemsList.filter(
          (x) => x.process_number == this.processNum
        );
      }

      //Exclude deleted forms
      itemsList = itemsList.filter((x) => x.is_deleted == "0");

      if (itemsList) {
        this.itemsList = itemsList.sort((a, b) => {
          return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
        });
      }
      this.partialItemsList = itemsList;
    });
  }

  sortListByDate() {
    if (this.partialItemsList) {
      this.sortAsc = !this.sortAsc;
      if (this.sortAsc) {
        this.partialItemsList = this.partialItemsList.sort((a, b) => {
          return a.inputMap.date < b.inputMap.date
            ? 1
            : a.inputMap.date > b.inputMap.date
            ? -1
            : 0;
        });
      } else {
        this.partialItemsList = this.partialItemsList.sort((a, b) => {
          return a.inputMap.date > b.inputMap.date
            ? 1
            : a.inputMap.date < b.inputMap.date
            ? -1
            : 0;
        });
      }
    }
  }

  populateNames() {
    this.adms.getUsers().subscribe((usersList) => (this.usersList = usersList));
  }

  populatePermissions() {
    const role_id = JSON.parse(localStorage.getItem("currentUser")).user
      .role_id;
    this.adms
      .getFormPermissions(role_id)
      .subscribe(
        (list) => (this.userPermission = list.map((x) => x.process_num))
      );
  }

  getEmployeeName(uid: string) {
    console.log("Getting employee " + uid);
    if (uid == null || this.usersList == null) {
      return "";
    }
    const user = this.usersList.find((x) => x.uid === uid);
    return user ? user.name : "-";
  }

  onDownloadPDF(id: string) {
    this.cds.downloadWorkOrderPDF(id).subscribe(
      (res) => {
        saveAs(res, "WorkOrder.pdf", { type: "application/pdf" });
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Unable to download form!");
      }
    );
  }

  onDownloadXls(id: string) {
    this.cds.downloadWorkOrderXls(id).subscribe(
      (res) => {
        saveAs(res, "WorkOrder.xls", { type: "application/vnd.ms-excel" });
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Unable to download form!");
      }
    );
  }

  onDownloadAll(jobnum: string) {
    this.downloadingAll = true;
    this.cds.downloadAllWithJobnum_zip(jobnum).subscribe(
      (res) => {
        this.downloadingAll = false;
        saveAs(res, "forms" + jobnum + ".zip", { type: "application/zip" });
      },
      (error) => {
        this.downloadingAll = false;
        // Display error message
        console.log(error);
        this.ssv.showError("Error: Unable to download forms!");
      }
    );
  }

  deactivateAllFormWithJobnum(jobnum: string) {
    //Get IDs of all IRFs with this job number
    this.cds.setActiveAllFormWithJobnum(jobnum, false).subscribe(
      (data) => {
        console.log("Deleted All forms with job number " + jobnum);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onClickForm(id) {
    this.focusedId = id;
  }

  /**
   * Reject form and send it back to Customer Service for submission (process #1)
   * @param id
   */
  onReject() {
    let model: Form = this.itemsList.find((x) => x.id == this.focusedId);

    //Change form state
    this.onSubmit("1", this.focusedId, "rejected");

    //Send email to Technician
    const recipient = this.usersList.find((x) => x.uid == model.filler_id);

    //TODO: Email sender
  }

  onApprove() {
    let model: Form = this.itemsList.find((x) => x.id == this.focusedId);

    //Change form state
    this.onSubmit("3", this.focusedId, "approved");

    //Send email to Customer Service (role = 7)
    const recipient = this.usersList.find(
      (x) => x.role_id == "7" && x.is_active == "1"
    );

    //TODO: Email sender
  }

  onSubmit(state, id, actionVerb) {
    //Close all modals
    document.getElementById("approveIRF").style.display = "none";
    document.getElementById("deleteIRFModal").style.display = "none";

    //Update form
    this.fms.setFormState(this.typeId, id, state).subscribe(
      (data) => {
        this.ssv.showSuccess("Form successfully " + actionVerb + "!");
        this.router.navigate(["/view-work-order/" + state]);
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Form failed to be " + actionVerb + "!");
      }
    );
  }

  /**
   * Recall form and make it available to edit (process #1)
   * @param id
   */
  onRecall(id: string) {
    this.fms.setFormState(this.typeId, id, "1").subscribe(
      (data) => {
        // Page redirect when getting response
        this.ssv.showSuccess("Form successfully recalled!");
        this.router.navigate(["/view-work-order"]);
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Unable to recall form!");
      }
    );
  }

  searchItem(input: string) {
    if (this.partialItemsList && this.itemsList) {
      this.partialItemsList = this.itemsList.filter(
        (x) =>
          (x.inputMap.job_num &&
            x.inputMap.job_num.toLowerCase().includes(input.toLowerCase())) ||
          (x.inputMap.project_name &&
            x.inputMap.project_name.toLowerCase().includes(input.toLowerCase()))
      );
    }
  }

  populateSTForms() {
    // this.fms.getForms('54', 10000, 'DESC', 0)
    // .subscribe(itemsList => {
    //     this.stockTransferList = itemsList.filter(x => x);
    // });
    this.nas.getWorkOrders("54").subscribe((itemsList) => {
      this.stockTransferList = itemsList.filter((x) => x);
    });
  }

  //Get ID of a stock transfer that has this job number
  getStockTransferId(jobnum: string) {
    let st = this.stockTransferList.find((x) => x.inputMap.job_num == jobnum);
    if (st) {
      return st.id;
    }
  }

  onDownloadStockTransfer(jobnum: string) {
    let st = this.stockTransferList.find((x) => x.inputMap.job_num == jobnum);
    if (st) {
      //Download XLS
      this.cds.downloadStockTransferXls(st.id).subscribe(
        (res) => {
          saveAs(res, "StockTransfer.xls", {
            type: "application/vnd.ms-excel",
          });
        },
        (error) => {
          // Display error message
          this.ssv.showError("Error: Unable to download form!");
        }
      );
    }
  }

  getIRFHeadersMap() {
    this.cds.getIRFHeaders("10000").subscribe((data) => {
      this.irfHeadersMap = data;
    });
  }

  toggleworkorder(id) {
    console.log("toggling " + id);
    if (this.selectedworkorderList.includes(id)) {
      const index = this.selectedworkorderList.indexOf(id, 0);
      if (index > -1) {
        this.selectedworkorderList.splice(index, 1);
      }
    } else {
      this.selectedworkorderList.push(id);
    }
  }

  onDeleteWorkOrder() {
    this.nas
      .deleteWorkOrder(this.selectedworkorderList)
      .subscribe((itemsList) => {
        this.partialItemsList = [];
        this.selectedworkorderList = [];
        this.ssv.showSuccess("Work Order successfully deleted");
        document.getElementById("btn_close_delete_work_order").click();
        this.populateNames();
        this.populatePermissions();
        this.populateItems();
        this.populateSTForms();
        switch (this.processNum) {
          case "1":
            this.formProcessState = "Draft";
            break;
          case "2":
            this.formProcessState = "Waiting Management Approval";
            break;
          case "3":
            this.formProcessState = "Ready for Billing";
            break;
          case "4":
            this.formProcessState = "Pending Archival";
            break;
          default:
            this.formProcessState = "All";
            break;
        }

        this.getIRFHeadersMap();
      });
  }
}
