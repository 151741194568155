import { Component, OnInit } from "@angular/core";
import {
  Form,
  Customer,
  FormManagementService,
  AdministrationService,
  AuthenticationService,
  Email,
  EmailNotificationService,
} from "process-smart-ng6-lib";
import { S3Manager } from "src/app/api/S3Manager";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "src/app/_services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-walkthrough",
  templateUrl: "./walkthrough.component.html",
  styleUrls: ["./walkthrough.component.css"],
})
export class WalkthroughComponent implements OnInit {
  formId: string;
  typeId = "49";
  model: Form;
  fillerUid: string;
  clientId: string;
  fillerName: string;
  focusedId: number;

  customersList: Array<Customer>; //List of all customers (for project name)
  custRecList: Array<Customer>; //List of filtered customers from search

  notesList: Array<any>; //List of all notes
  currFocus: number; //Index of item currently being focused
  nextItemNum: number;

  attachments1: Array<File>;
  attachments2: Array<File>;
  attachments3: Array<File>;

  isDraft = true;
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private fms: FormManagementService,
    private ssv: SharedService,
    private aus: AdministrationService,
    private router: Router,
    private adms: AuthenticationService,
    private ems: EmailNotificationService
  ) {}

  ngOnInit() {
    //Get user info from session
    this.fillerUid = JSON.parse(localStorage.getItem("currentUser")).user.uid;
    this.fillerName = JSON.parse(localStorage.getItem("currentUser")).user.name;
    this.clientId = JSON.parse(
      localStorage.getItem("currentUser")
    ).user.client_id;

    //Populate customers for search
    this.getCustomersList();

    //Create list of empty attachment slots
    this.attachments1 = [];
    this.attachments2 = [];
    this.attachments3 = [];

    //Get form ID if specified on route.
    //If specified, load the form.
    this.route.params.subscribe((params) => {
      this.formId = params["formId"];

      if (this.formId) {
        //Load Form
        this.getModel();
      } else {
        //Create a new form
        this.model = <Form>{
          id: "0",
          type_id: this.typeId,
          status_id: "1",
          process_number: "2",
          created_date: this.toHtmlDate(null),
          last_modified_date: this.toHtmlDate(null),
          filler_id: this.fillerUid,
          modifier_id: this.fillerUid,
          inputMap: {},
          inputTables: {
            notes: [],
          },
        };
        this.notesList = [];
        this.nextItemNum = 1;
      }
    });
  }

  getModel() {
    this.fms.getForm(this.typeId, this.formId).subscribe((model) => {
      model.created_date = this.toHtmlDate(model.created_date);
      model.last_modified_date = this.toHtmlDate(null);

      if (model.inputMap.date) {
        let date = new Date(model.inputMap.date);
        date.setDate(date.getDate() + 15);
        model.inputMap.complete_by = this.ssv.toHtmlDateStr(date);
      }

      this.model = model;
      this.notesList = model.inputTables.notes;
      this.nextItemNum =
        +this.notesList[this.notesList.length - 1].item_number + 1;

      for (let i = 0; i < this.notesList.length; i++) {
        this.attachments1.push(null);
        this.attachments2.push(null);
        this.attachments3.push(null);
      }
    });
  }

  onSubmit() {
    this.isSubmitting = true;
    //Add "-" to empty note fields
    this.notesList.forEach((n) => {
      if (!n.address) {
        n.address = "-";
      }
      if (!n.description) {
        n.description = "-";
      }
    });

    //Collect all notes
    this.model.inputTables.notes = this.notesList;
    //Upload form data to DB
    if (this.formId) {
      //Update an existing form
      this.fms.updateForm(this.model, this.typeId, this.formId).subscribe(
        (data) => {
          //Save attachments in S3
          for (let num = 1; num <= 3; num++) {
            for (
              let noteIdx = 0;
              noteIdx < this.attachments1.length;
              noteIdx++
            ) {
              if (
                this.model.inputTables.notes[noteIdx]["attachment" + num] &&
                this["attachments" + num][noteIdx]
              ) {
                S3Manager.uploadFormAttachment(
                  this["attachments" + num][noteIdx],
                  this.model.inputTables.notes[noteIdx]["attachment" + num],
                  this.clientId
                );
              }
            }
          }

          // Page redirect when getting response
          if (this.model.process_number == "3") {
            this.notifyPunchlistReady();
            this.ssv.showSuccess("Walkthrough successfully sent!");
          } else {
            this.ssv.showSuccess("Walkthrough successfully updated!");
          }

          this.router.navigate(["/view-walkthrough/2"]);
        },
        (error) => {
          // Display error message
          this.isSubmitting = false;
          this.ssv.showError("Unable to update form!");
        }
      );
    } else {
      //Create a new form
      this.fms.newForm(this.model, this.typeId).subscribe(
        (data) => {
          //Save attachments in S3
          for (let num = 1; num <= 3; num++) {
            for (
              let noteIdx = 0;
              noteIdx < this.attachments1.length;
              noteIdx++
            ) {
              if (
                this.model.inputTables.notes[noteIdx]["attachment" + num] &&
                this["attachments" + num][noteIdx]
              ) {
                S3Manager.uploadFormAttachment(
                  this["attachments" + num][noteIdx],
                  this.model.inputTables.notes[noteIdx]["attachment" + num],
                  this.clientId
                );
              }
            }
          }

          // Page redirect when getting response
          if (this.model.process_number == "3") {
            this.notifyPunchlistReady();
            this.ssv.showSuccess("Walkthrough successfully sent!");
          } else {
            this.ssv.showSuccess("Walkthrough successfully updated!");
          }

          this.router.navigate(["/view-walkthrough/2"]);
        },
        (error) => {
          // Display error message
          this.isSubmitting = false;
          this.ssv.showError("Unable to update form!");
        }
      );
    }
  }

  onClickForm(id) {
    this.focusedId = +id;
  }

  onSubmitProceed() {
    //Update process number if form is submitted (not just saved as draft)
    this.model.process_number = "3";
    this.onSubmit();
  }

  toHtmlDate(datestr: string) {
    let date;
    if (datestr) {
      date = new Date(datestr);
    } else {
      date = new Date();
    }
    const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const mm =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const yyyy = date.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }

  //MULTI INPUT FIELDS
  addNote() {
    let fid;
    if (this.formId) {
      fid = this.formId;
    } else {
      fid = "0";
    }
    const mat = { form_id: fid, item_number: "" + this.nextItemNum };
    this.notesList.push(mat);

    //Add counter to next item number
    this.nextItemNum++;

    //Add attachment size
    this.attachments1.push(null);
    this.attachments2.push(null);
    this.attachments3.push(null);
  }

  onDeleteNote() {
    document.getElementById("deleteWTModal").style.display = "none";

    const note = this.notesList[this.focusedId];
    this.notesList.splice(this.focusedId, 1);

    //Delete attachment(s)
    this.attachments1.splice(this.focusedId, 1);
    this.attachments2.splice(this.focusedId, 1);
    this.attachments3.splice(this.focusedId, 1);

    //Delete from server
    let inputMap = { inputMap: note };
    this.fms
      .deleteMultiInput(inputMap, this.typeId, "notes", "item_number")
      .subscribe(
        (data) => {},
        (error) => {
          console.log(error);
        }
      );
  }

  //CUSTOMER SEARCH
  getCustomersList() {
    this.aus.getCustomers().subscribe((list) => {
      this.customersList = list;
    });
  }

  onCustomerSelected(custId: string) {
    const customer: Customer = this.customersList.find((x) => x.id == custId);
    this.model.inputMap.project_name = customer.project_name;
    this.model.inputMap.management_company = customer.management;
    this.model.inputMap.management_email = customer.email;
    this.custRecList = [];
  }

  searchCustomer(input: string) {
    if (this.customersList) {
      this.custRecList = this.customersList.filter((x) =>
        x.project_name.toLowerCase().includes(input.toLowerCase())
      );
    }
  }

  notifyPunchlistReady() {
    //Get Ayla's email
    this.adms.getUser("57").subscribe(
      (user) => {
        if (user.email) {
          //Send Email
          const recipientList = [user.email];
          const ccList = [];
          const bccList = [];
          const subject = "Punchlist Ready";
          const content =
            user.name +
            "," +
            "<br><br>A Punchlist is ready for download." +
            "<br>Please download the punchlist from the dashboard.";
          const email: Email = {
            subject: subject,
            body: content,
            to: recipientList,
            cc: ccList,
            bcc: bccList,
          };
          this.ems.sendEmail(email).subscribe((data) => {});
        } else {
          console.log("Email recipient not found");
        }
      },
      (error) => {
        console.log(error);
        // Display error message
        this.ssv.showError("Error: Email recipient not found!");
      }
    );
  }

  onFileAdded(event, noteIdx, num) {
    if (event.target.files === undefined || event.target.files.length == 0) {
      this["attachments" + num][noteIdx] = null;
      delete this.notesList[noteIdx]["attachment" + num];
    } else {
      const ext = event.target.files[0].name.substr(
        event.target.files[0].name.lastIndexOf(".")
      );
      this["attachments" + num][noteIdx] = event.target.files[0];
      this.notesList[noteIdx]["attachment" + num] =
        "form" +
        this.ssv.newGuid() +
        "_note" +
        (+noteIdx + 1) +
        "_att" +
        num +
        ext;
    }
  }

  //DIAGNOSTIC
  get diagnostic() {
    return JSON.stringify(this.model);
  }
  get ndiagnostic() {
    return JSON.stringify(this.notesList);
  }

  getS3FormPicUrl(filename) {
    return S3Manager.getFormAttachmentUrl(filename, this.clientId);
  }
}
