﻿import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  // protected basePath = 'https://api.personitech.com:8443/ProcessSmart';
  protected basePath = "https://stageapi.nisshoca.com:8443/ProcessSmart";

  constructor(private http: HttpClient) {}

  login(uname_login: string, pass_login: string) {
    // const body = new URLSearchParams();
    // body.set('uname_login', uname_login);
    // body.set('pass_login', pass_login);
    // const options = {
    //     headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    // };

    return this.http
      .post<any>(
        `${this.basePath}/ClientLogin?uname_login=${uname_login}&pass_login=${pass_login}`,
        {}
      )
      .pipe(
        map((session) => {
          //Check if user belongs in this client (client ID in environments/environment[.prod].ts)
          if (environment.allow_client != session.user.client_id) {
            return null;
          }
          // login successful if there's a token in the response
          else if (session && session.apiKey) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(session));
            return session;
          } else {
            return null;
          }
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
  }
}
