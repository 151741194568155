import { Component, OnInit } from "@angular/core";
import { Form, User } from "process-smart-ng6-lib";
import {
  FormManagementService,
  DocumentConversionService,
  AuthenticationService,
  EmailNotificationService,
  Email,
} from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from "file-saver";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { NewApiService } from "src/app/_services/newApi";

@Component({
  selector: "app-view-walkthrough",
  templateUrl: "./view-walkthrough.component.html",
  styleUrls: ["./view-walkthrough.component.css"],
})
export class ViewWalkthroughComponent implements OnInit {
  itemsList: Array<Form>;
  partialItemsList: Array<any>;
  usersList: Array<User>;
  userPermission: Array<string>; //Contains a list of process numbers this role is responsible for
  typeId = "49";
  processNum: string;
  note: string;
  roleId: string;
  uid: string;
  reject_reason: string;
  focusedId: string;

  constructor(
    private fms: FormManagementService,
    private adms: AuthenticationService,
    private cds: CustomDownloadServiceService,
    private ssv: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private ems: EmailNotificationService,
    private nas: NewApiService
  ) {}

  ngOnInit() {
    this.reject_reason = "";
    this.populatePermissions();
    this.populateNames();

    this.route.params.subscribe((params) => {
      this.processNum = params["processNum"];
      this.populateItems();
    });

    this.uid = JSON.parse(localStorage.getItem("currentUser")).user.uid;
    this.roleId = JSON.parse(localStorage.getItem("currentUser")).user.role_id;
  }

  populateItems() {
    console.log("processNum", this.processNum, this.uid, this.typeId);
    /*this.fms.getForms(this.typeId, 200, "DESC", 0).subscribe((itemsList) => {
      const newItem = itemsList.filter((x: any) => x.id == 35035);
      console.log("itemsList", newItem);
      if (this.processNum && itemsList) {
        itemsList = itemsList.filter(
          (x) => x.process_number == this.processNum
        );

        //Filter out deleted ones
        itemsList = itemsList.filter((x) => x.is_deleted == "0");
        //Filter by user if PM or Management
        if (this.roleId == "3" || this.roleId == "2") {
          itemsList = itemsList.filter((x) => x.filler_id == this.uid);
        }

        if (itemsList) {
          this.ssv.alerts["wt_" + this.processNum] = itemsList.length;
        }

        this.itemsList = itemsList.sort((a, b) => {
          return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
        });
      }
      this.partialItemsList = itemsList;
    });*/

    this.nas
      .getViewWalkThrough(this.typeId, this.processNum, this.uid)
      .subscribe((itemsList) => {
        if (itemsList) {
          this.ssv.alerts["wt_" + this.processNum] = itemsList.length;
          this.itemsList = itemsList.sort((a, b) => {
            return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
          });

          if (this.roleId == "3" || this.roleId == "2") {
            this.itemsList = this.itemsList.filter(
              (x) => x.filler_id == this.uid
            );
          }
          this.partialItemsList = this.itemsList;
        } else {
          this.itemsList = [];
          this.partialItemsList = this.itemsList;
        }
      });
  }

  populateNames() {
    this.adms.getUsers().subscribe((usersList) => (this.usersList = usersList));
  }

  populatePermissions() {
    const role_id = JSON.parse(localStorage.getItem("currentUser")).user
      .role_id;
    this.adms
      .getFormPermissions(role_id)
      .subscribe(
        (list) =>
          (this.userPermission = list
            .filter((x) => x.form_type_id == this.typeId)
            .map((x) => x.process_num))
      );
  }

  getEmployeeName(uid: string) {
    if (uid == null || this.usersList == null) {
      return "";
    }
    const user = this.usersList.find((x) => x.uid === uid);
    return user ? user.name : "-";
  }

  onDownloadPDF(id: string) {
    //Disable download until response received
    this.partialItemsList.find((x) => x.id == id).is_downloading_pdf = true;

    this.cds.downloadWalkthroughPDF(id).subscribe(
      (res) => {
        saveAs(res, "Punchlist.pdf", { type: "application/pdf" });
        //Re-enable download
        this.partialItemsList.find((x) => x.id == id).is_downloading_pdf =
          false;
      },
      (error) => {
        // Display error message
        console.log(error);
        this.ssv.showError("Error: Unable to download form!");

        //Re-enable download
        this.partialItemsList.find((x) => x.id == id).is_downloading_pdf =
          false;
      }
    );
  }

  onDownloadDocx(id: string) {
    this.partialItemsList.find((x) => x.id == id).is_downloading_docx = true;

    this.cds.downloadWalkthroughDocx(id).subscribe(
      (res) => {
        saveAs(res, "Punchlist.docx", {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        this.partialItemsList.find((x) => x.id == id).is_downloading_docx =
          false;
      },
      (error) => {
        // Display error message
        console.log(error);
        this.ssv.showError("Error: Unable to download form!");
        this.partialItemsList.find((x) => x.id == id).is_downloading_docx =
          false;
      }
    );
  }

  onDelete() {
    //Close all modals
    document.getElementById("approveIRF").style.display = "none";
    document.getElementById("deleteIRFModal").style.display = "none";

    this.fms
      .setFormDeactivateStatus(this.typeId, this.focusedId, true)
      .subscribe(
        (data) => {
          this.ssv.countForm("wt_3", "49", null, "3");
          this.ssv.showSuccess("Form successfully deleted!");
          window.location.reload();
        },
        (error) => {
          // Display error message
          this.ssv.showError("Error: Form deletion failed!");
        }
      );
  }

  onRecall(id: string) {
    this.fms.setFormState(this.typeId, id, "2").subscribe(
      (data) => {
        this.ssv.countForm("wt_3", "49", null, "3");
        // Page redirect when getting response
        this.ssv.showSuccess("Form successfully recalled.");
        window.location.reload();
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Unable to recall form!");
      }
    );
  }

  onReject() {
    let model: Form = this.itemsList.find((x) => x.id == this.focusedId);

    //Change form state
    this.fms.setFormState(this.typeId, this.focusedId, "2").subscribe(
      (data) => {
        this.ssv.countForm("wt_3", "49", null, "3");
        // Page redirect when getting response
        //Email project manager
        this.notifyPunchlistRejection(this.focusedId, model.filler_id);
        this.ssv.showSuccess("Form successfully returned to Project Manager.");
      },
      (error) => {
        // Display error message
        console.log(error);
        this.ssv.showError("Error: Unable to reject form!");
      }
    );
  }

  onClickForm(id) {
    this.focusedId = id;
  }

  searchItem(input: string) {
    if (this.partialItemsList && this.itemsList) {
      this.partialItemsList = this.itemsList.filter((x) =>
        x.inputMap.project_name.toLowerCase().includes(input.toLowerCase())
      );
    }
  }

  /**
   * Notify walkthrough proposal rejection to the Project Manager
   * @param note - Management note containing reason for rejection
   * @throws Exception when form retrieval from database fails or email cannot be sent
   */
  notifyPunchlistRejection(formId, fillerId) {
    //Get form filler as recipient
    this.adms.getUser(fillerId).subscribe(
      (user) => {
        if (user.email) {
          //Send Email
          const recipientList = [user.email];
          const ccList = [];
          const bccList = [];
          const subject = "Punchlist Revision Request";
          const content =
            user.name +
            "," +
            "<br><br>A Punchlist has been returned" +
            (this.note
              ? "."
              : " with the following note:<br><i>" + this.note + "</i>") +
            "<br><br>Please login to the Dashboard to revise or discard the form." +
            "<br>The form will be placed in the <i>Draft</i> section.";
          const email: Email = {
            subject: subject,
            body: content,
            to: recipientList,
            cc: ccList,
            bcc: bccList,
          };
          console.log(JSON.stringify(email));
          this.ems
            .sendEmail(email)
            .subscribe((data) => window.location.reload());
        } else {
          console.log("Email recipient not found");
        }
      },
      (error) => {
        console.log(error);
        // Display error message
        this.ssv.showError("Error: Email recipient not found!");
      }
    );
  }
}
