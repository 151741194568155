import { Component, OnInit } from "@angular/core";
import { Form, User } from "process-smart-ng6-lib";
import {
  FormManagementService,
  DocumentConversionService,
  AuthenticationService,
  EmailNotificationService,
  Email,
  Material,
  InventoryManagementService,
} from "process-smart-ng6-lib";
import { SharedService } from "src/app/_services/shared.service";
import { saveAs } from "file-saver";
import { ActivatedRoute } from "@angular/router";
import { CustomDownloadServiceService } from "src/app/_services/custom-download-service.service";
import { Router } from "@angular/router";
import { S3Manager } from "src/app/api/S3Manager";
import { NewApiService } from "src/app/_services/newApi";
import { bool } from "aws-sdk/clients/signer";

@Component({
  selector: "app-view-irrigation-repair",
  templateUrl: "./view-irrigation-repair.component.html",
  styleUrls: ["./view-irrigation-repair.component.css"],
})
export class ViewIrrigationRepairComponent implements OnInit {
  itemsList: Array<Form>;
  partialItemsList: Array<any>;
  usersList: Array<User>;
  userPermission: Array<any>; //Contains a list of process numbers this role is responsible for
  typeId = "47";
  processNum: string;
  paramJobnum: string;
  downloadingAll: boolean;
  selectedIRFList: Array<string>;

  uid: string;
  roleId: string;
  clientId: string;
  focusedId: string;
  reject_reason: string;
  jobnum: string;
  jobNumsAvailable: Array<string>;
  formProcessState: string;
  woList: Array<Form>;
  stList: Array<Form>;
  matList: Array<Material>; //For pricing

  typeId3 = "45"; //Work Order
  typeId2 = "54"; //Stock Transfer
  model: Form; //Work Order
  model2: Form; //Stock Transfer
  fillerUid: string;
  jobnumberfulllist: Array<any>;
  jobnumberlist: Array<any>;
  showDropdown: boolean;
  showpopDropdown: boolean;
  header_id: any;

  constructor(
    private fms: FormManagementService,
    private adms: AuthenticationService,
    private dcs: DocumentConversionService,
    private ssv: SharedService,
    private cds: CustomDownloadServiceService,
    private route: ActivatedRoute,
    private ems: EmailNotificationService,
    private router: Router,
    private ims: InventoryManagementService,
    private nas: NewApiService
  ) {}

  ngOnInit() {
    this.header_id = "0";
    this.selectedIRFList = [];
    this.reject_reason = "";
    this.uid = JSON.parse(localStorage.getItem("currentUser")).user.uid;
    this.roleId = JSON.parse(localStorage.getItem("currentUser")).user.role_id;
    this.clientId = JSON.parse(
      localStorage.getItem("currentUser")
    ).user.client_id;
    this.initEmptyWoAndSt();
    this.populateNames();
    this.populatePermissions();
    this.populateWOList();
    this.populateSTList();
    this.populateMatList();
    this.getJobNumberList();
    this.downloadingAll = false;

    this.route.params.subscribe((params) => {
      this.processNum = params["processNum"];
      this.paramJobnum = params["jobnum"];
      this.populateItems();
      switch (this.processNum) {
        case "1":
          this.formProcessState = "Draft";
          break;
        case "2":
          this.formProcessState = "Waiting Manager Approval";
          break;
        case "3":
          this.formProcessState = "Awaiting Office Staff Approval";
          break;
        case "4":
          this.formProcessState = "Assigned to Work Order";
          break;
        default:
          this.formProcessState = "All";
          break;
      }
    });

    // this.nas.getViewPurchaseOrder(this.roleId).subscribe(data => {
    //     console.log("data", data)
    // })
  }

  populateMatList() {
    this.ims.getMaterials("1").subscribe((matList) => {
      this.matList = matList;
    });
  }

  getJobNumberList() {
    this.nas.getJobNumberList().subscribe((itemsList: any) => {
      this.jobnumberlist = itemsList;
      this.jobnumberfulllist = itemsList;
    });
  }

  hideDropdown(type) {
    setTimeout(() => {
      if (type == "popup") {
        this.showpopDropdown = false;
      } else {
        this.showDropdown = false;
      }
    }, 400);
  }
  onJobNumberChange(value, type) {
    this.header_id = "0";
    this.jobNumberSearch(type);
  }

  jobNumberSearch(type) {
    if (this.jobnum) {
      this.jobnumberlist = this.jobnumberfulllist.filter((x) => {
        return x.job_num.toLowerCase().includes(this.jobnum.toLowerCase());
      });
      if (type == "popup") {
        this.showpopDropdown = true;
      } else {
        this.showDropdown = true;
      }
    } else {
      if (type == "popup") {
        this.showpopDropdown = true;
      } else {
        this.showDropdown = true;
      }
      this.jobnumberlist = this.jobnumberfulllist;
    }
  }

  selectJobNumber(text, id) {
    this.jobnum = text;
    this.header_id = id;
  }

  populateItems() {
    // this.fms.getForms(this.typeId, 10000, 'DESC', 0)
    //     .subscribe(itemsList => {
    //         console.log("this.processNum", this.processNum, this.paramJobnum)
    //         if (this.processNum) {
    //             //Only include ones at this current process number
    //             itemsList = itemsList.filter(x => x.process_number == this.processNum);

    //             //Only include non-deleted forms
    //             itemsList = itemsList.filter(x => x.is_deleted == '0');

    //             //If available, filter by job number
    //             if (this.paramJobnum) {
    //                 itemsList = itemsList.filter(x => x.inputMap.job_num != null && x.inputMap.job_num.trim() == this.paramJobnum);
    //             }

    //             //If this is drafts list or user is technician, only allow owner to see
    //             if (this.processNum == '1' || this.roleId == '6') {
    //                 itemsList = itemsList.filter(x => x.filler_id == this.uid);
    //             }

    //             if (itemsList) {
    //                 this.ssv.alerts['irf_' + this.processNum] = itemsList.length;
    //             }
    //         }

    //         if (itemsList) {
    //             this.itemsList = itemsList.sort((a, b) => {
    //                 return (a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
    //             });
    //         }
    //         this.partialItemsList = itemsList;
    //     });
    let paramJobnum = this.paramJobnum ? this.paramJobnum : "";
    this.nas
      .getViewWalkThrough(this.typeId, this.processNum, this.uid, paramJobnum)
      .subscribe((itemsList) => {
        console.log("itemsList", itemsList);
        if (itemsList) {
          this.itemsList = itemsList.sort((a, b) => {
            return a.id < b.id ? 1 : a.id > b.id ? -1 : 0;
          });
          if (this.processNum == "1" || this.roleId == "6") {
            this.itemsList = this.itemsList.filter(
              (x) => x.filler_id == this.uid
            );
          }

          this.partialItemsList = this.itemsList;
        } else {
          this.itemsList = [];
          this.partialItemsList = this.itemsList;
        }
      });
  }

  populateNames() {
    this.adms.getUsers().subscribe((usersList) => (this.usersList = usersList));
  }

  populatePermissions() {
    const role_id = JSON.parse(localStorage.getItem("currentUser")).user
      .role_id;
    this.adms
      .getFormPermissions(role_id)
      .subscribe(
        (list) =>
          (this.userPermission = list
            .filter((x) => x.form_type_id == this.typeId)
            .map((x) => x.process_num))
      );
  }

  getEmployeeName(uid: string) {
    if (uid == null || this.usersList == null) {
      return "";
    }
    const user = this.usersList.find((x) => x.uid === uid);
    return user ? user.name : "-";
  }

  onDownloadPDF(id: string) {
    //Disable download until response received
    this.partialItemsList.find((x) => x.id == id).is_downloading_pdf = true;

    this.cds.downloadIrrigationRepairPDF(id).subscribe(
      (res) => {
        saveAs(res, "IrrigationForm.pdf", { type: "application/pdf" });
        //Re-enable download
        this.partialItemsList.find((x) => x.id == id).is_downloading_pdf =
          false;
      },
      (error) => {
        // Display error message
        console.log(error);
        this.ssv.showError("Error: Unable to download form!");

        //Re-enable download
        this.partialItemsList.find((x) => x.id == id).is_downloading_pdf =
          false;
      }
    );
  }

  searchItem(input: string) {
    if (this.partialItemsList && this.itemsList) {
      if (!input) {
        this.partialItemsList = this.itemsList;
      } else {
        this.partialItemsList = this.itemsList.filter(
          (x) =>
            (x.inputMap.job_num &&
              x.inputMap.job_num.toLowerCase().includes(input.toLowerCase())) ||
            (x.inputMap.job_name &&
              x.inputMap.job_name
                .toLowerCase()
                .includes(input.toLowerCase())) ||
            (x.filler_id &&
              this.getEmployeeName(x.filler_id)
                .toLowerCase()
                .includes(input.toLowerCase()))
        );
      }
    }
  }

  onSubmit(state, id, actionVerb) {
    //Close all modals
    document.getElementById("approveIRF").style.display = "none";
    document.getElementById("approveCIRF").style.display = "none";
    document.getElementById("deleteIRFModal").style.display = "none";

    //Update form
    this.fms.setFormState(this.typeId, id, state).subscribe(
      (data) => {
        this.ssv.countForm("irf_3", "47", null, "3");
        this.ssv.showSuccess("Form successfully " + actionVerb + "!");
        this.router.navigate(["/view-irrigation-repair/" + state]);
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Form failed to be " + actionVerb + "!");
      }
    );
  }

  onIrrigationManagerApprove() {
    let model: Form = this.itemsList.find((x) => x.id == this.focusedId);

    //Change form state
    this.onSubmit("3", this.focusedId, "approved");

    //Send email to Customer Service (role = 7)
    const recipient = this.usersList.find(
      (x) => x.role_id == "7" && x.is_active == "1"
    );
    this.sendIRFManagerApprovalEmail(recipient.uid);
  }

  onIrrigationManagerReject() {
    let model: Form = this.itemsList.find((x) => x.id == this.focusedId);

    //Change form state
    this.onSubmit("1", this.focusedId, "rejected");

    //Send email to Technician
    const recipient = this.usersList.find((x) => x.uid == model.filler_id);
    this.sendIRFManagerRejectionEmail(recipient.uid);
  }

  /**
   * 1. Create WO and ST
   * 2. For all IRFs, assign jobnum
   */
  onCustomerServiceApproveAll() {
    this.createWoAndStThenAssignIRFs();
  }

  addToIrfList(irfId: string) {
    const index = this.selectedIRFList.indexOf(irfId, 0);
    if (index === -1) {
      this.selectedIRFList.push(irfId);
    }
  }

  removeFromIrfList(irfId: string) {
    const index = this.selectedIRFList.indexOf(irfId, 0);
    if (index > -1) {
      this.selectedIRFList.splice(index, 1);
    }
  }

  initEmptyWoAndSt() {
    //Init Work Order model
    this.model = <Form>{
      id: "0",
      type_id: this.typeId3,
      status_id: "1",
      process_number: "1",
      created_date: this.ssv.toHtmlDate(null),
      last_modified_date: this.ssv.toHtmlDate(null),
      filler_id: this.fillerUid,
      modifier_id: this.fillerUid,
      inputMap: {
        date: this.ssv.toHtmlDate(null),
      },
      inputTables: {
        materials: [],
        labors: [],
      },
    };

    //Init Stock Transfer model
    this.model2 = <Form>{
      id: "0",
      type_id: this.typeId2,
      status_id: "1",
      process_number: "1",
      created_date: this.ssv.toHtmlDate(null),
      last_modified_date: this.ssv.toHtmlDate(null),
      filler_id: this.fillerUid,
      modifier_id: this.fillerUid,
      inputMap: {
        date: this.ssv.toHtmlDate(null),
      },
      inputTables: {
        materials: [],
      },
    };
  }

  createWoAndStThenAssignIRFs() {
    //Create a new work order and ST, then assign IRFs
    this.model.inputMap.job_num = this.jobnum;
    this.fms.newForm(this.model, this.typeId).subscribe(
      (data) => {
        let woId = data["formId"];
        //If successful, create a new stock transfer
        this.model2.inputMap.job_num = this.jobnum;
        this.fms.newForm(this.model2, this.typeId2).subscribe(
          (data) => {
            // Created ST successfully, now assign IRFs
            this.assignJobnumToIRFs();
          },
          (error) => {
            // Display error message
            this.ssv.showError("Unable to create stock transfer!");
          }
        );
      },
      (error) => {
        // Display error message
        this.ssv.showError("Unable to start job!");
      }
    );
  }

  toggleIrf(id) {
    console.log("toggling " + id);
    if (this.selectedIRFList.includes(id)) {
      const index = this.selectedIRFList.indexOf(id, 0);
      if (index > -1) {
        this.selectedIRFList.splice(index, 1);
      }
    } else {
      this.selectedIRFList.push(id);
    }
  }

  //Iterate over list of IRF IDs and assign one by one
  assignJobnumToIRFs() {
    let countIrf = this.selectedIRFList.length;
    if (countIrf <= 0) {
      console.log("Error: Empty IRF list");
      return;
    }

    let materialsToAdd = [];
    let irfLeft = countIrf;
    for (let i = 0; i < countIrf; i++) {
      let model: Form = this.itemsList.find(
        (x) => x.id == this.selectedIRFList[i]
      );
      if (model) {
        model.created_date = this.toHtmlDate(null);
        model.last_modified_date = this.toHtmlDate(null);
        model.process_number = "4";
        model.inputMap.job_num = this.jobnum;
        this.fms
          .updateForm(model, this.typeId, this.selectedIRFList[i])
          .subscribe(
            (data) => {
              //IRF Update successful; now update stock transfer and work order
              //Get IRF matlist (to add to ST)
              irfLeft--;
              const irfMatList = model.inputTables.materials;
              for (let j = 0; j < irfMatList.length; j++) {
                let itemIdx = materialsToAdd.findIndex(
                  (x) => x.material_id == irfMatList[j].material_id
                );
                if (itemIdx === -1) {
                  //Current material list to add to ST does not contain the i-th material in this IRF
                  materialsToAdd.push(irfMatList[j]);
                } else {
                  //Material already exists, add its quantity
                  materialsToAdd[itemIdx].quantity =
                    "" +
                    (+materialsToAdd[itemIdx].quantity +
                      +irfMatList[j].quantity);
                }
              }

              if (irfLeft <= 0) {
                //Update stock transfer with aggregate materials from all IRFs
                this.updateStockTransfer(this.jobnum, materialsToAdd);
              }
            },
            (error) => {
              // Display error message
              this.ssv.showError("Error: Failed to approve form!");
            }
          );
      }
    }
  }

  onCustomerServiceApprove() {
    //Close all modals
    document.getElementById("approveIRF").style.display = "none";
    document.getElementById("approveCIRF").style.display = "none";
    document.getElementById("deleteIRFModal").style.display = "none";
    document.getElementById("changeCIRF").style.display = "none";
    //Update form
    if (this.jobnum && this.jobNumsAvailable.includes(this.jobnum)) {
      let model: Form = this.itemsList.find((x) => x.id == this.focusedId);
      if (model) {
        model.created_date = this.toHtmlDate(null);
        model.last_modified_date = this.toHtmlDate(null);
        model.process_number = "4";
        model.inputMap.job_num = this.jobnum;
        this.fms.updateForm(model, this.typeId, this.focusedId).subscribe(
          (data) => {
            //IRF Update successful; now update stock transfer and work order
            //Get IRF matlist (to add to ST)
            const irfMatList = model.inputTables.materials;
            this.updateStockTransfer(this.jobnum, irfMatList);
            //Delete IRF materials from ST with the previous job number so we don't add duplicate items
            if (this.paramJobnum) {
              this.removeMatListFromST(this.paramJobnum, irfMatList);
            }
          },
          (error) => {
            // Display error message
            this.ssv.showError("Error: Failed to approve form!");
          }
        );
      }
    } else {
      this.ssv.showError("Error: Job number does not exist!");
      //Close all modals
      document.getElementById("approveIRF").style.display = "none";
      document.getElementById("approveCIRF").style.display = "none";
      document.getElementById("deleteIRFModal").style.display = "none";
      document.getElementById("changeCIRF").style.display = "none";
    }
  }

  onCustomerServiceReject() {
    let model: Form = this.itemsList.find((x) => x.id == this.focusedId);

    //Change form state
    this.onSubmit("2", this.focusedId, "rejected");

    //Send email to Irrigation Manager (approver)
    const recipient = this.usersList.find(
      (x) => x.role_id == "4" && x.is_active == "1"
    );
    this.sendIRFCSRejectionEmail(recipient.uid);
  }

  removeMatListFromST(jobnum: string, irfMatList: Array<any>) {
    //Get Stock Transfer
    let st: Form = this.getStockTransfer(jobnum);

    if (!st.inputTables) {
      st.inputTables = [];
    }
    if (!st.inputTables.materials) {
      st.inputTables.materials = [];
    }

    //Iterate over IRF and add parts/quantity
    let matToDelete = [];
    irfMatList.forEach((x) => {
      //Find part in ST
      let y = st.inputTables.materials.find(
        (y) => y.material_id == x.material_id
      );

      //Get parts pricing from materials list
      let price = this.matList.find((z) => z.id == x.material_id).price;

      //Update Qty if it exists in ST
      if (y) {
        //Material found in Stock Transfer
        y.quantity = "" + (+y.quantity - +x.quantity);

        //Delete if quantity reaches 0
        if (y.quantity == "0") {
          matToDelete.push(y.material_id);
        }
      }
      //If Material not found in ST, ignore
    });

    //Update qty in form
    st.created_date = this.ssv.toHtmlDate(st.created_date);
    st.last_modified_date = this.ssv.toHtmlDate(null);

    this.fms.updateForm(st, "54", st.id).subscribe(
      (data) => {
        //Delete if quantity reaches 0
        matToDelete.forEach((y) => {
          let body = {
            inputMap: {
              form_id: "" + st.id,
              material_id: "" + y,
            },
          };
          this.fms
            .deleteMultiInput(body, "54", "materials", "material_id")
            .subscribe((data) => {
              console.log("Deleted");
            });
        });
      },
      (error) => {
        console.log("ST Update error");
      }
    );
  }

  //If material to add already exists in ST, update their qty in ST;
  //Otherwise, add this item to ST
  updateStockTransfer(jobnum: string, irfMatList: Array<any>) {
    //Get Stock Transfer
    let st: Form = this.getStockTransfer(jobnum);

    if (!st.inputTables) {
      st.inputTables = [];
    }
    if (!st.inputTables.materials) {
      st.inputTables.materials = [];
    }

    //Iterate over IRF and add parts/quantity
    irfMatList.forEach((x) => {
      //Find part in ST
      let y = st.inputTables.materials.find(
        (y) => y.material_id == x.material_id
      );

      //Get parts pricing from materials list
      let price = this.matList.find((z) => z.id == x.material_id).price;

      //Update Qty & price if it exists in ST
      if (y) {
        //Material found in Stock Transfer
        y.quantity = "" + (+x.quantity + +y.quantity);
        y.price = "" + Math.round(+price * 100) / 100;
      } else {
        //Material not found in ST, create a new one
        x.price = "" + Math.round(+price * 100) / 100;
        st.inputTables.materials.push(x);
      }
    });

    //Fix dates
    st.created_date = this.ssv.toHtmlDate(st.created_date);
    st.last_modified_date = this.ssv.toHtmlDate(null);

    this.fms.updateForm(st, "54", st.id).subscribe(
      (data) => {
        this.ssv.showSuccess("Form successfully approved!");
        //this.router.navigate(['/view-irrigation-repair/3']);
        this.redirectTo("/view-irrigation-repair/3");
      },
      (error) => {
        console.log("ST Update error");
      }
    );
  }

  redirectTo(uri: string) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  onDelete() {
    //Close all modals
    document.getElementById("approveIRF").style.display = "none";
    document.getElementById("approveCIRF").style.display = "none";
    document.getElementById("deleteIRFModal").style.display = "none";

    this.fms
      .setFormDeactivateStatus(this.typeId, this.focusedId, true)
      .subscribe(
        (data) => {
          this.ssv.showSuccess("Form successfully deleted!");
          window.location.reload();
        },
        (error) => {
          // Display error message
          this.ssv.showError("Error: Form deletion failed!");
        }
      );
  }

  onRecall() {
    //Close all modals
    document.getElementById("recallIRF").style.display = "none";

    this.fms.setFormState(this.typeId, this.focusedId, "2").subscribe(
      (data) => {
        // Page redirect when getting response
        this.ssv.showSuccess("Form successfully recalled.");
        this.router.navigate(["/view-irrigation-repair/2"]);
      },
      (error) => {
        // Display error message
        this.ssv.showError("Error: Unable to recall form!");
      }
    );
  }

  onClickForm(id, type) {
    if (type == "admin_review") {
      this.jobnumberlist = this.jobnumberfulllist;
    }
    this.focusedId = id;
  }

  //Send email to Technician
  sendIRFManagerRejectionEmail(recipientId) {
    //Get form filler as recipient
    this.adms.getUser(recipientId).subscribe(
      (user) => {
        if (user.email) {
          //Send Email
          const recipientList = [user.email];
          const ccList = [];
          const bccList = [];
          const subject = "Irrigation Repair Form Revision Request";
          const content =
            user.name +
            "," +
            "<br><br>An Irrigation Repair form has been rejected" +
            (!this.reject_reason
              ? "."
              : " with the following reason:<br><i>" +
                this.reject_reason +
                "</i>") +
            "<br><br>Please login to the Dashboard to revise or discard the form." +
            "<br>The form will be placed in the <i>Draft</i> section.";
          const email: Email = {
            subject: subject,
            body: content,
            to: recipientList,
            cc: ccList,
            bcc: bccList,
          };
          this.ems.sendEmail(email).subscribe((data) => {});
        } else {
          console.log("Email recipient not found");
        }
      },
      (error) => {
        console.log(error);
        // Display error message
        this.ssv.showError("Error: Email recipient not found!");
      }
    );
  }

  //Send email to Irrigation Manager
  sendIRFCSRejectionEmail(recipientId) {
    //Get form filler as recipient
    this.adms.getUser(recipientId).subscribe(
      (user) => {
        if (user.email) {
          //Send Email
          const recipientList = [user.email];
          const ccList = [];
          const bccList = [];
          const subject = "Irrigation Repair Form Revision Request";
          const content =
            user.name +
            "," +
            "<br><br>An Irrigation Repair form has been rejected" +
            (!this.reject_reason
              ? "."
              : " with the following reason:<br><i>" +
                this.reject_reason +
                "</i>") +
            "<br><br>Please login to the Dashboard to revise or discard the form." +
            "<br>The form will be placed in the <i>Draft</i> section.";
          const email: Email = {
            subject: subject,
            body: content,
            to: recipientList,
            cc: ccList,
            bcc: bccList,
          };
          this.ems.sendEmail(email).subscribe((data) => {});
        } else {
          console.log("Email recipient not found");
        }
      },
      (error) => {
        console.log(error);
        // Display error message
        this.ssv.showError("Error: Email recipient not found!");
      }
    );
  }

  //Send email to Customer Service
  sendIRFManagerApprovalEmail(recipientId) {
    //Get form filler as recipient
    this.adms.getUser(recipientId).subscribe(
      (user) => {
        if (user.email) {
          //Send Email
          const recipientList = [user.email];
          const ccList = [];
          const bccList = [];
          const subject = "Irrigation Repair Job Number Request";
          const content =
            user.name +
            "," +
            "<br><br>An Irrigation Repair requires your approval." +
            "<br>Please login to the Dashboard to review the repair.";
          const email: Email = {
            subject: subject,
            body: content,
            to: recipientList,
            cc: ccList,
            bcc: bccList,
          };
          this.ems.sendEmail(email).subscribe((data) => {});
        } else {
          console.log("Email recipient not found");
        }
      },
      (error) => {
        console.log(error);
        // Display error message
        this.ssv.showError("Error: Email recipient not found!");
      }
    );
  }

  //Get active Stock Transfer with this job number
  getStockTransfer(jobnum: string) {
    if (!jobnum || !this.stList) {
      this.ssv.showError("Error: Cannot find corresponding Stock Transfer!");
    }
    let st = this.stList.find((x) => x.inputMap.job_num == jobnum);
    return st;
  }

  //Get active Work Order with this job number
  getWorkOrder(jobnum: string) {
    if (!jobnum || !this.stList) {
      this.ssv.showError("Error: Cannot find corresponding Work Order!");
    }
    return this.woList.find((x) => x.inputMap.job_num == jobnum);
  }

  populateWOList() {
    this.nas.getProcessnum("45").subscribe((itemsList) => {
      if (this.processNum) {
        //Only include non-deleted forms
        itemsList = itemsList.filter((x) => x.is_deleted == "0");

        //Populate job number list
        this.jobNumsAvailable = itemsList.map((x) => x.inputMap.job_num);

        //Find one with matching job number
        if (this.paramJobnum) {
          itemsList = itemsList.filter(
            (x) => x.inputMap.job_num == this.paramJobnum
          );
        }
      }

      //Populate Work Order list
      if (itemsList) {
        this.woList = itemsList;
      }
    });
  }

  populateSTList() {
    // this.fms.getForms('54', 10000, 'DESC', 0)
    this.nas.getProcessnum("54").subscribe((itemsList) => {
      if (this.processNum) {
        //Only include non-deleted forms
        itemsList = itemsList.filter((x) => x.is_deleted == "0");

        //Find one with matching job number
        //                if(this.paramJobnum){
        //                    itemsList = itemsList.filter(x => x.inputMap.job_num == this.paramJobnum);
        //                }
      }

      //Populate Stock Transfer list
      if (itemsList) {
        this.stList = itemsList;
      }
    });
  }

  //Download zip of all irrigation repairs of the same job number
  onDownloadAll() {
    if (this.itemsList && this.paramJobnum) {
      let id_list = this.itemsList.map((x) => x.id);
      this.downloadingAll = true;
      this.cds.downloadIrrigationRepairPDF_zip(id_list).subscribe(
        (res) => {
          this.downloadingAll = false;
          saveAs(res, "irflist.zip", { type: "application/zip" });
        },
        (error) => {
          this.downloadingAll = false;
          // Display error message
          console.log(error);
          this.ssv.showError("Error: Unable to download forms!");
        }
      );
    }
  }

  getS3FormPicUrl(filename) {
    return S3Manager.getFormAttachmentUrl(filename, this.clientId);
  }

  toHtmlDate(datestr: string) {
    let date;
    if (datestr) {
      date = new Date(datestr);
    } else {
      date = new Date();
    }
    const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    const mm =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    const yyyy = date.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }
}
